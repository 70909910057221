import * as React from 'react'
import Layout from '../../components/layout'
import { API, graphqlOperation } from 'aws-amplify'
import { createBrewery } from '../../graphql/mutations'

const AddBrewery = () => {
  async function addBrewery() {
    const brewery = { name: 'My new brewer' }
    await API.graphql(graphqlOperation(createBrewery, { input: brewery }))
  }

  return <Layout>
    <button onClick={addBrewery}>Add brewery</button>
  </Layout>
}

export default AddBrewery